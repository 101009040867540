import React, {Component} from 'react';


class Portfolio extends Component{
    render(){
        return(
            <div className="container-fluid servicepage">
                <div className="row serviceheader">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pageheader">
                                <h2>Portfolio</h2>
                                <p>
                                    Our work is as good as the team that we put at your disposal. And we always want you to be sure that the best people are working for you. That’s why under one roof we have gathered IT people with different sets of skills – software devs, UX/UI designers and PMs.
                                </p>
                            </div>                            
                        </div>                       
                    </div>
                </div>
                <div className="row ">
                     <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                     </div>
                </div>


            </div>            
        );
    }
}

export default Portfolio;