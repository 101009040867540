import React, {Component} from 'react';
import logo from './images/logo.png';
import {Link} from 'react-router-dom'
//import '../App.css';

class Header extends Component{
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render(){
        return(               
            <header className="container-fluid">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-6 col-md-6 col-lg-2 col-xl-2 logosection">
                            <Link to="/"><img src={logo} className="" alt="CollabDot" /></Link> 
                            </div>
                            <div className="col-6 col-md-6 col-lg-10 col-xl-10">
                                {/*<nav className="navbar">
                                    <ul>
                                        <li><Link to="/Services">Service</Link></li>
                                        <li><Link to="/Portfolio">Portfolio</Link></li>
                                        <li>Testimonials</li>
                                        <li><Link to="/ERP/Eskool">Team</Link></li>
                                        <li>Contact</li>
                                    </ul>
                                </nav>*/}
                                <nav className="navbar navbar-expand-lg navbar-light">
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                            <i class="fa fa-bars"></i>
                                        </button>
                                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                            <ul className="navbar-nav">                                                
                                                <li className="nav-item dropdown">
                                                    <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Services
                                                    </Link>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <Link to="/ERP" className="dropdown-item">ERP Solutions</Link>
                                                    <Link to="/supermarket" className="dropdown-item">RetailEZY</Link>
                                                    <Link to="/medEZY" className="dropdown-item">MedEZY</Link>
                                                    <Link to="/Mobileapp" className="dropdown-item">App Development</Link>
                                                    <Link to="/iot" className="dropdown-item">IoT Solutions</Link>
                                                    <Link to="/ailm" className="dropdown-item">AI & Machine Learning</Link>
                                                    <Link to="/Training" className="dropdown-item">Skill Enhancement</Link>
                                                    </div>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/Company" className="nav-link">Company</Link>
                                                </li>
                                                {/*<li className="nav-item">
                                                    <Link to="/Portfolio" className="nav-link">Portfolio</Link>
                                                    </li> */}                                               
                                                <li className="nav-item">
                                                    <Link to="/Team" className="nav-link">Team</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/Contact" className="nav-link">Contact</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                            </div>
                        </div> 
                    </div>
                </div>                
            </header>            
        );
    }
}

export default Header;