import React, {Component} from 'react';
//import grouppic from './images/straining.jpg';
import cloudsolutions from './images/cloudsolutions.jpg';
import Digitaltransformation from './images/Digitaltransformation.jpg';
import Customservices from './images/Customservices.jpg';
import Maintanance from './images/Maintanance.jpg'; 



function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }


class Wedo extends Component{
    componentDidMount() {
        window.scrollTo(0, 0)
      }
      
    render(){
        return(
            <div className="container-fluid servicepage">
                <div className="row serviceheader">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>What We Do ?</h2>
                                <p>
                                Our work is as good as the team that we put at your disposal. And we always want you to be sure that the best people are working for you. That’s why under one roof we have gathered IT people with different sets of skills.
                                </p>
                            </div>                            
                        </div>                       
                    </div>
                </div>
                <div className="row">
                    <div className="container paddingbottom">
                        <div className="row wedosection">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h3>Cloud solutions</h3>
                                <hr />
                                <p>                        
                                    Businesses are adopting cloud at a rapid pace to meet evolving customer and employee demands. This enhanced their employee productivity, operational efficiency, growth, and profit. NTCS cloud strategy enables the organizations to drive business acceleration, connected insights, and customer experience. We provide a seamless and secure journey from in-house IT to a modern cloud-based enterprise environment. Your business can benefit from our cloud services at any stage of your cloud adoption journey. Our services are well-equipped to uplift your business to the next level of cloud transformation. The transformation helps customers leverage the best of architecture, application performance, infrastructure, and security without disrupting business continuity. Our proven approach, architectures, frameworks, and partner ecosystem has enabled businesses to realize the full potential of cloud via our services like advisory, development, architecture.  

                                </p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={cloudsolutions} className="img-fluid" alt="CollabDots" />
                            </FadeInSection>
                            </div>
                        </div>
                        <div className="row wedosection">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={Digitaltransformation} className="img-fluid" alt="CollabDots" />
                            </FadeInSection>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h3>Digital transformation</h3>
                                <hr />
                                <p>                        
                                    Digital transformation is setting new standards across business and enterprises. It is not just incorporating new technologies in your business but it also brings about a sea change in your enterprise. Existing products are reinvented, manual processes are swapped for automated models, and decision-making turns more precise with data. Digital Transformation, therefore, is far more than just a technology change but it also involves training people and changing processes to get more out of the most powerful tool in business. It gives you an insight into your data and enables you to take strategic decisions. Technologies such as Artificial Intelligence, cloud computing, the Internet of Things, and more are fundamentally changing businesses the world over and we at NTCS believe in bridging this gap to take your business to the next level.
                                </p>
                            </div>                            
                        </div>
                        <div className="row wedosection">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h3>Custom services</h3>
                                <hr />
                                <p>                        
                                    Whether you’re building a mobile app or a complex web application the web is at the core of your software platform. And with new cloud services and capabilities coming online every day the web is more powerful than ever for shaping the way we live and work. Our team dedicates itself in developing custom web applications for your business requirements. We have the experience to help you navigate the complex world of available technologies and build a robust and scalable platform to power your business. Our team of developers will create a beautiful and truly interactive UI for your applications.
                                </p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={Customservices} className="img-fluid" alt="CollabDots" />
                            </FadeInSection>
                            </div>
                        </div>
                        <div className="row wedosection">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={Maintanance} className="img-fluid" alt="CollabDots" />
                            </FadeInSection>                                
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h3>Maintenance and support</h3>
                                <hr />
                                <p>                        
                                    All IT companies spend a share of their budget on in-house maintenance, correction and upgrading of software. We at NTCS has been catering to companies as well as local software vendors in maintenance and support services of the technologies they develop. We channelize our resources to provide fool-proof, real-time and long-term software maintenance and support services. Our maintenance and support includes bug fixing problems ,modifications to existing systems, adding new features and functionalities, recoding, remodelling and reconstructing of software upon requirement.
                                </p>
                            </div>                            
                        </div>
                    </div>                     
                </div>


            </div>            
        );
    }
}

export default Wedo;