import React, {Component} from 'react';
import team1 from './images/bhawani.jpg';
//import team2 from './images/shom.jpg';
import team3 from './images/arnab.jpg';
import team4 from './images/firoj.jpg';
import team5 from './images/ujjal.jpg';
import team6 from './images/arunima.jpg';
import team7 from './images/abinash.jpg';
import group1 from './images/team-bbsr.jpg';
import group2 from './images/team-lulung.jpg';
import group3 from './images/team-ghana.jpg';
import group4 from './images/team-chilka.jpg';
//import grouppic from './images/training.jpg';
//import grouppic1 from './images/straining.jpg';

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  
class Team extends Component{
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    
    render(){
        return(
            <div className="container-fluid servicepage">
                <div className="row teamheader">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                       {/* <h1></h1>*/}
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>Meet Our Team</h2>
                                <p>
                                Not every organization can boast of a team who volunteer their time and 
                                services after work hours. <br />We are proud of our team who always goes 
                                the extra mile to get the job done
                                </p>
                            </div>                            
                        </div>
                        <div className="row">
                            <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1"></div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
                                <div className="row teampic">
                                    <div className="col-lg-1 col-xl-1">
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 imgcircle">                                        
                                        <img src={team1}  alt="Mr. Bhawani Shankar Pattnaik" />  
                                        <i className="fa fa-linkedin"></i>
                                        <h3>Bhawani</h3>
                                        <div class="content">
                                            <div class="text"></div>
                                        </div>                                        
                                    </div>
                                    {/*<div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 imgcircle">
                                        <img src={team2}  alt="Dr. Shom Prasad Das" />
                                        <i className="fa fa-linkedin"></i>
                                        <h3>Shom</h3>
                                        <div class="content">
                                            <div class="text"></div>
                                        </div>
                                    </div>*/}
                                    <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 imgcircle">
                                        <img src={team3}  alt="Arnab Banerjee" />
                                        <i className="fa fa-linkedin"></i>
                                        <h3>Arnab</h3>
                                        <div class="content">
                                            <div class="text"></div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 imgcircle">
                                    <img src={team4}  alt="Firoj Kumar Subudhi" />
                                    <i className="fa fa-linkedin"></i>
                                    <h3>Firoj</h3>
                                    <div class="content">
                                        <div class="text"></div>
                                    </div>
                                </div>
                                     
                                    <div className="col-lg-3 col-xl-2">
                                    </div>                                   
                                </div>
                                <div className="row teampic">
                                <div className="col-lg-1 col-xl-1">
                                </div>
                                <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 imgcircle">
                                        <img src={team5}  alt="Ujjal Dey" />
                                        <i className="fa fa-linkedin"></i>
                                        <h3>Ujjal</h3>
                                        <div class="content">
                                            <div class="text"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 imgcircle">
                                        <img src={team6}  alt="Arunima Sambhuta Pattanayak" />
                                        <i className="fa fa-linkedin"></i>
                                        <h3>Arunima</h3>
                                        <div class="content">
                                            <div class="text"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 imgcircle">
                                        <img src={team7}  alt="Abinash Sahu" />
                                        <i className="fa fa-linkedin"></i>
                                        <h3>Abinash</h3>
                                        <div class="content">
                                            <div class="text"></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-xl-2">
                                    </div>
                                </div>
                            </div>
                            <div className="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1"></div>
                        </div>                        
                    </div>
                </div>

                <div className="row teambottomsection">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h5>At the end of the day, it’s all about being the best at our NTCS.</h5>
                    </div>
                </div>
                <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>Life @ NTCS</h2>
                            </div>                            
                        </div>
                <div className="row photosection">
                    <div className="container">
                    <FadeInSection>
                        <div className="row grouppicsection">                        
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <img src={group1} className="img-fluid" alt="CollabDots" />
                                <p></p>
                            </div>                        
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <img src={group2} className="img-fluid" alt="CollabDots" />
                                <p></p>
                            </div>
                        </div>
                    </FadeInSection>
                    <FadeInSection>
                        <div className="row grouppicsection ">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <img src={group3} className="img-fluid" alt="CollabDots" />
                                <p></p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <img src={group4} className="img-fluid" alt="CollabDots" />
                                <p></p>
                            </div>
                        </div>
                        </FadeInSection>
                    </div>
                </div>


            </div>            
        );
    }
}

export default Team;