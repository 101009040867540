import React, {Component} from 'react';
import billingpic from './images/billing.jpg';
import billingpic1 from './images/billing1.jpg';
import easybilling from './images/easybilling.png';
import Inventory from './images/Inventory.png';
import reporting from './images/reporting.png';
import expiry from './images/expiry.png';
import billing from './images/receipt_black_24dp.svg';
import inventory from './images/inventory_black_24dp.svg';
import support from './images/support_agent_black_24dp.svg';
import trending from './images/trending_up_black_24dp.svg';
import mobileApp from './images/book_online_black_24dp.svg';
import gst from './images/assignment_turned_in_black_24dp.svg';
import Retailezy from './images/retailEZY.png';



//import $ from 'jquery';

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  



class Supermarket extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
      }
    render(){
        return(

            <div className="container-fluid servicepage">
                <div className="row supermarketbanner">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row mb-4">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <img src={Retailezy} className="img-fluid brandinglogo" alt="Supermarket" />
                                <p>
                                    A Smart Store Management System for all Business Needs                                   
                                </p>                              
                            </div>
                        </div>
                    </div>                    
                </div>

                <div className="row mt-3 mb-5">
                    <div className="container">
                        <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10">
                        
                            <div className="row pb-3">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 icondiv" >
                                <FadeInSection>
                                    <div className="servicediv">                                    
                                        <img src={inventory} className="img-fluid icon-mg" alt="Supermarket" />
                                        <div id="hoverShow" className="opendiv">
                                            <div className="text-center" >
                                                <img src={inventory} alt="Supermarket" />
                                            </div>                                        
                                            <hr />
                                            <span >Inventory Management</span>                                            
                                            <ul>
                                                <li>Maintain Purchase and Stock </li>
                                                <li>Manage Selling Price and Discounts At One Click</li>
                                                <li>Set Reorder Points</li>
                                            </ul>
                                        </div>                                                                      
                                    </div>
                                    <h5>Inventory</h5>
                                </FadeInSection>                                                              
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 icondiv">
                                <FadeInSection>
                                    <div className="servicediv1">                                    
                                        <img src={billing} className="img-fluid icon-mg" alt="Supermarket" />
                                        <div id="hoverShow1" className="opendiv">
                                            <div className="text-center" >
                                                <img src={billing} alt="Supermarket" />
                                            </div>                                        
                                            <hr />
                                            <span >Billing System</span>                                            
                                            <ul>
                                                <li>Easy Computerised Billing </li>
                                                <li>Show MRP, Discounts, and Rewards Earned</li>
                                                <li>Easy Refund and Cancellation Handling</li>
                                            </ul>
                                        </div>                                                                       
                                    </div>
                                    <h5>Billing</h5>
                                </FadeInSection>                                
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 icondiv">
                                <FadeInSection>
                                    <div className="servicediv2">                                    
                                        <img src={support} className="img-fluid icon-mg" alt="Supermarket" />
                                        <div id="hoverShow2" className="opendiv">
                                            <div className="text-center" >
                                                <img src={support} alt="Supermarket" />
                                            </div>                                        
                                            <hr />
                                            <span >Customer Relations</span>                                            
                                            <ul>
                                                <li>Creates Customer Database </li>
                                                <li>Enables Messaging to Individuals/Groups</li>
                                                <li>Helps Understand Customer Behaviour</li>
                                            </ul>
                                        </div>                                                                      
                                    </div>
                                    <h5>Customer Relations</h5>
                                </FadeInSection>                                
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 icondiv" >
                                <FadeInSection>
                                    <div className="servicediv3">                                    
                                        <img src={trending} className="img-fluid icon-mg" alt="Supermarket" />
                                        <div id="hoverShow3" className="opendiv">
                                            <div className="text-center" >
                                                <img src={trending} alt="Supermarket" />
                                            </div>                                        
                                            <hr />
                                            <span >Analytics</span>                                            
                                            <ul>
                                                <li>Auto Generated Periodic Reports (Sales, Profit etc) </li>
                                                <li>Help Boost Customer Footfall</li>
                                                <li>Personalised/Customised Business Approach</li>
                                            </ul>
                                        </div>                                                                       
                                    </div>
                                    <h5>Analytics</h5>
                                </FadeInSection>                               
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 icondiv">
                                <FadeInSection>
                                    <div className="servicediv4">                                    
                                        <img src={mobileApp} className="img-fluid icon-mg" alt="Supermarket" />
                                        <div id="hoverShow4" className="opendiv">
                                            <div className="text-center" >
                                                <img src={mobileApp} alt="Supermarket" />
                                            </div>                                        
                                            <hr />
                                            <span >App Based Orders</span>                                            
                                            <ul>
                                                <li>Provides Online Platform through Mobile App </li>
                                                <li>Enables Home Delivery</li>
                                                <li>Enhances Customer Reach</li>
                                            </ul>
                                        </div>                                                                        
                                    </div>
                                    <h5>App Based Orders</h5>
                                </FadeInSection>                               
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 icondiv">
                                <FadeInSection>
                                    <div className="servicediv5">                                    
                                        <img src={gst} className="img-fluid icon-mg" alt="Supermarket" />
                                        <div id="hoverShow5" className="opendiv">
                                            <div className="text-center" >
                                                <img src={gst} alt="Supermarket" />
                                            </div>                                        
                                            <hr />
                                            <span >GST Filing</span>                                            
                                            <ul>
                                                <li>Segregate GST and Non-GST Billing </li>
                                                <li>Hassle-free GST Calculations</li>
                                                <li>Assistance in GST Filing</li>
                                            </ul>
                                        </div>                                                                      
                                    </div>
                                    <h5>GST Filing</h5>
                                </FadeInSection>                               
                                </div>
                            </div>
                        </div>
                        <div className="col-1"></div>                        
                            
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 col-lg-12 col-md-12 col-sm-12 mt-5">
                                <h6>Please click link for demo: <a href="http://collabdots.com/ProductDemo/RetailEzy/login.php" target="_blank">http://collabdots.com/ProductDemo/RetailEzy/login.php</a></h6>                               
                            </div>
                        </div>
                    </div>                    
                </div>

                

{/*
                <div className="row">
                    <div className="container paddingbottom">
                        <div className="row wedosection">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h3>Supermarket Software</h3>
                                <hr />
                                <p>                        
                                Supermarket Software is Easy & fast billing, easy monitoring, and control over stocks reduce pilferage, get best deals from suppliers with smart purchase features, discount & scheme management, user configurable invoicing along with 1000’s of reports to keep you in full control of your business.
                                <br /><br />
                                Supermarket!!!! It is a large form of a grocery store which offers a wide variety of foods and household products that are well organized into aisles. In supermarket business, accurate inventory information is essential as many of the items are perishable with limited shelf lives. If the stocks of fast selling items are less then it will lead to loss of sales and even if the stocks of low selling items are more then it will also lead in loss of sales. So it means properly organized inventory/stocks should be maintained so that items should not be over-ordered or overstocked as it does not scramble the path of grabbing better opportunities. Supermarket Software contains such features which distinguish the software from other software’s. 
                                </p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={billingpic} className="img-fluid" alt="Supermarket" />
                            </FadeInSection>
                            </div>
                        </div>
                        <div className="row wedosection">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={billingpic1} className="img-fluid" alt="Supermarket" />
                            </FadeInSection>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h3>Why do businesses choose Erp to manage Supermarket business?</h3>
                                <hr />
                                <ul className="text-left">
                                    <li>GST Compliant</li>
                                    <li>Easy Implementation</li>
                                    <li>Customizable</li>
                                    <li>Collaborative</li>
                                    <li>Business Booster</li>
                                    <li>Customer - Driven Features</li>
                                </ul>
                            </div>                            
                        </div>
                    </div>                     
                </div>

                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <h2>We Provide Supermarket Software</h2>
                                <p>
                                Supermarket SOFTWARE Simple to Use Easy to Implement Flexible to Customized Solution
                                </p>                                
                            </div>
                        </div>
                        <div className="row mt-4 mb-4">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div className="providediv">
                                    <FadeInSection>
                                        <img src={easybilling} className="img-fluid" alt="Supermarket"/>
                                    </FadeInSection>
                                    <h4>Easy & Fast Billing</h4><hr />
                                    <p>Generate batch wise invoices ,with detailed information about different Businesses</p>
                                </div>                                
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div className="providediv">
                                    <FadeInSection>
                                        <img src={Inventory} className="img-fluid" alt="Supermarket"/>
                                    </FadeInSection>
                                    <h4>Inventory</h4><hr />
                                    <p>Manage inventory levels, set reorder points to replenish stock and save your loses due to expiry.</p>
                                </div>                                
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div className="providediv">
                                    <FadeInSection>
                                        <img src={reporting} className="img-fluid" alt="Supermarket"/>
                                    </FadeInSection>
                                    <h4>GST Filing</h4><hr />
                                    <p>Push transactions into the GSTN portal from excel, JSON or CSV file and file your GST returns directly.</p>
                                </div>                                
                            </div>
                        </div>

                        <div className="row mt-4 mb-4">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div className="providediv">
                                    <FadeInSection>
                                        <img src={expiry} className="img-fluid" alt="Supermarket"/>
                                    </FadeInSection>
                                    <h4>Auto-Bank Reconciliation</h4>
                                    <p>Avail online banking option with ICICI Bank and Auto-Bank reconciliation facility with 140+ Banks only in Marg ERP</p>
                                </div>                                
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div className="providediv">
                                    <FadeInSection>
                                        <img src={billingpic1} className="img-fluid" alt="Supermarket"/>
                                    </FadeInSection>
                                    <h4>Import Purchase</h4>
                                    <p>Allow to import purchase from any excel or csv format to save your time with 100% accuracy</p>
                                </div>                                
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div className="providediv">
                                    <FadeInSection>
                                        <img src={billingpic1} className="img-fluid" alt="Supermarket"/>
                                    </FadeInSection>
                                    <h4>Reporting</h4>
                                    <p>Stay on top of your finances with powerful 1000’s of sales, purchase, inventory & accounting reports</p>
                                </div>                                
                            </div>
                        </div>

                        <div className="row mt-4 mb-4">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div className="providediv">
                                    <FadeInSection>
                                        <img src={billingpic1} className="img-fluid" alt="Supermarket"/>
                                    </FadeInSection>
                                    <h4>Re-Order Management</h4>
                                    <p>Help you reallocate your items to other things, save money and time and automate the entire reorder process</p>
                                </div>                                
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div className="providediv">
                                    <FadeInSection>
                                        <img src={billingpic1} className="img-fluid" alt="Supermarket"/>
                                    </FadeInSection>
                                    <h4>Cashier and Home Delivery</h4>
                                    <p>Cashier management and home delivery feature gives you 100% control on your business</p>
                                </div>                                
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div className="providediv">
                                    <FadeInSection>
                                        <img src={billingpic1} className="img-fluid" alt="Supermarket"/>
                                    </FadeInSection>
                                    <h4>Expiry Management</h4>
                                    <p>Keeps a track of expiry date and timely return to supplier before the product expires.</p>
                                </div>                                
                            </div>
                        </div>
                    </div>                    
                </div>*/}
            </div>            
        );
    }
}

export default Supermarket;