import React, {Component} from 'react';
import aiml from './images/aiml.png';
import aiml1 from './images/aiml1.png';



function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }


class Ailm extends Component{
    render(){
        return(
            <div className="container-fluid servicepage">
                <div className="row AIMLheader">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>AI & Machine Learning</h2>
                            </div>                            
                        </div>                       
                    </div>
                </div>
                <div className="row">
                    <div className="container paddingbottom">
                        <div className="row wedosection">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0">
                                <p className="ailm-p">                        
                                    Advancements in data science have made it possible to automate the production of machine learning (ML) models that can help analyse large, complex datasets and deliver more accurate results faster. By building models, an organization is more likely to identify profitable opportunities or avoid risks. These advancements in data science has led to a wide interest and adoption of machine learning, which can be used in many applications for natural language processing, cybersecurity, infrastructure management, healthcare decision support, training and education, and more.
                                </p>
                                <p>                        
                                    We at NTCS has developed data management methodology that incorporates the use of machine learning and artificial intelligence for various use cases and clients. This methodology uses business analysis and development of ML models.
                                </p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={aiml} className="img-fluid" alt="CollabDots"/>
                            </FadeInSection>
                            </div>
                        </div>
                        <div className="row wedosection">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={aiml1} className="img-fluid" alt="CollabDots" />
                            </FadeInSection>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h3>Our Skills and Capabilities</h3>
                                <hr />
                                <ul className="text-left">                                    
                                    <li>Develop and manage data pipelines at enterprise scale</li>
                                    <li>Build data expertise and own data quality for various data flows</li>
                                    <li>Leads backend and ETL development effort of Customer facing Business Intelligence Applications.</li>
                                    <li>Designs, maintains, and performance-tunes extraction, transformation, and load (ETL) processes using SQL and Hadoop stack</li>
                                    <li>Experience with SQL, Python/R, Redshift, Glue and RDS.</li>
                                    <li>Rapid development and prototyping in Python.</li>
                                    <li>Work with engineering teams to implement new models.</li>
                                    <li>Develop scalable algorithms and methods</li>
                                </ul>
                            </div>                            
                        </div>

                    </div>                     
                </div>


            </div>            
        );
    }
}

export default Ailm;