import React, {Component} from 'react';
//import banner from '../images/1.jpg';
//import '../App.css';
//import services from './images/services.png';
import team1 from './images/bhawani.jpg';
//import team2 from './images/shom.jpg';
import team3 from './images/arnab.jpg';
import team4 from './images/firoj.jpg';
import team5 from './images/ujjal.jpg';
import team6 from './images/arunima.jpg';
import team7 from './images/abinash.jpg';
import MI from './images/MI.jpg';
import Cerp from './images/corporateerp.jpg';
import AppDev from './images/appdev.jpg';
import IoT from './images/iot.jpg';
//import StartupIndia from './images/StartupIndia.jpg';
//import StartupOdisha from './images/StartupOdisha.jpg';
import Clients from './images/claints.png';
//import sunmoksha from './images/sunmoksha.png';
import NIST from './images/nist.png';
import Sankalp from './images/sankalpsemi.png'
//import { Redirect } from 'react-router-dom';
//import Team from './team';
//import Footer from './footer';
import {Link} from 'react-router-dom';

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  /*function clickbutton(pagename){
    if(pagename=="Team"){
        alert("aa");
        return <Redirect to="/Team/" />
        }
    }*/

class Home extends Component{
    /*constructor(props){
        super(props);

        //this.state = {pagename:""}
        this.clickbutton=this.clickbutton.bind(this);
    }
     clickbutton (pagename) {
        if(pagename=="Team"){
           this.props.history.push("/Team");
        }
    }*/

    componentDidMount() {
        window.scrollTo(0, 0)
      }

    render(){
        return(
            <div className="container-fluid bannersection">
                <div className="row banner">
                    <div className="col-lg-7 col-xl-7">                        
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                        <div className="bannertxt">
                            <h1>IT Development <br />AI & Machine Learning <br />IoT, Embedded System</h1>
                            <h4>Create Custom Software Solutions Designed Specifically for your Business</h4>
                            <div className="banner-btn"><Link to="/wedo">Explore Our Solutions</Link></div>
                        </div>                        
                    </div>
                </div>
                <div className="row">
                    <div className="container servicesection">
                        {/*
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>Our Services</h2>
                                <p>
                                    NTCS creates solutions for businesses, industries,<br/>
                                    and the public to let them benefit from the mobile transformation.
                                </p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 servicetxt">
                                <h5>NTCS creates solutions for businesses</h5>
                                <p>
                                    NTCS creates solutions for businesses, industries,
                                    and the public to let them benefit from the mobile transformation.
                                    NTCS creates solutions for businesses, industries,
                                    and the public to let them benefit from the mobile transformation.
                                    NTCS creates solutions for businesses, industries,
                                    and the public to let them benefit from the mobile transformation.
                                </p>
                                <div className="home-btn">Explore Our Solutions</div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <img src={services} className="img-fluid" alt="ntcs" />
                            </div>
                        </div>*/}
                        <div className="row">
                    <div className="container">
                        <div className="row">
                            {/*<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pageheader">
                                <h2>Custom Software<br /> Development & Design Services</h2>
                            </div>*/}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>Our Products</h2>
                                <p>
                                    We at NTCS create diverse, complex web and mobile applications to meet the challenges of the emerging technologies. The Engineers of NTCS create secure and scalable IoT solutions that fits your business needs. The highly efficient team unleashes the power of data with the help of machine learning to give a strategic advantage to the modern business world.                                     
                                </p>                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <div className="productblock">
                                    <img src={Cerp} className="productimage" alt="CollabDots ERP Solutions" />
                                    <div className="products">
                                        Providing ERP Solutions for Institutions and Corporates
                                        <h5><Link to="/erp">ERP Solutions <i class="fa fa-arrow-circle-right"></i></Link></h5>
                                    </div>
                                </div>
                            </FadeInSection>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <div className="productblock">
                                    <img src={MI} className="productimage-R" alt="CollabDots Machine Learning" />
                                    <div className="products-R">
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Strategic Decision Making 
                                        <h5><Link to="/ailm">Machine Learning <i class="fa fa-arrow-circle-right"></i></Link></h5>
                                    </div>                               
                                </div>
                            </FadeInSection>
                            </div>
                        </div>

                        <div className="row productrow">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <div className="productblock">                                
                                    <img src={AppDev} className="productimage" alt="CollabDots Mobile APP" />
                                    <div className="products">
                                        Easy to Use Mobile APP for Bringing Solutions at your Finger Tips
                                        <h5><Link to="/mobileapp">App Development <i class="fa fa-arrow-circle-right"></i></Link></h5>
                                    </div>                                
                                </div>
                            </FadeInSection>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <div className="productblock">                                
                                    <img src={IoT} className="productimage-R" alt="CollabDots IoT" />
                                    <div className="products-R">
                                        Scalable IoT Solutions to fit your Business Needs
                                        <h5><Link to="/iot">IoT Solutions <i class="fa fa-arrow-circle-right"></i></Link></h5>
                                    </div>                                    
                                </div>
                            </FadeInSection>
                            </div>
                        </div>
                    </div>                    
                </div>
                    </div>
                </div>        
                <div className="row whatwedo">
                    {/*<div className="container-fluid whyntcs">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>Why work with ntcs?</h2>
                                <p>
                                    Partnering with ntcs for your mobile/web project means<br />
                                    working with a top polish mobile development company<br />
                                    that’s with you every step of the way, and beyond.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 whyntcscount">
                                        <h1>23</h1>
                                        <p>Years on the market</p>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 whyntcscount">
                                        <h1>200<span>+</span></h1>
                                        <p>Finished projects</p>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 whyntcscount">
                                        <h1>50<span>+</span></h1>
                                        <p>Employees</p>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 whyntcscount lastdiv">
                                        <h1>30</h1>
                                        <p>Verified reviews on Clutch</p>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>*/}
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 whatedotxt">
                                <h1>What We Do</h1>
                                <p>
                                We work in different technologies like IT development, Renewal Energy, 
                                Embedded System, VLSI design and Fabrication.  Our mission is to provide
                                 quality training, solutions and services in different areas of Information 
                                 Technology to our trainees and clients. We Provide quality services to our 
                                 clients while ensuring the maximum possible value for money. 
                                 We listen to your ideas and create custom software solutions from the 
                                 ground up, designed especially for your business.
                                </p>
                                <div className="home-btn"><Link to="/wedo">Explore Our Solutions</Link></div>
                                
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                                <div className="row">
                                   
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 whatwedodiv ">
                                        <FadeInSection>
                                            <Link to="/wedo">
                                                <div className="whatwedoblock">
                                                    <i class="fa fa-cloud"></i>
                                                    <p>Cloud <br />Solutions</p>
                                                </div>
                                            </Link>  
                                        </FadeInSection>                                      
                                    </div>
                                    
                                    
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 whatwedodiv">
                                        <FadeInSection>
                                             <Link to="/wedo">
                                                <div className="whatwedoblock">
                                                    <i class="fa fa-cogs"></i>
                                                    <p>Digital <br />Transformation</p>
                                                </div>
                                            </Link>  
                                        </FadeInSection>                                      
                                    </div>
                                    
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 whatwedodiv">
                                        <FadeInSection>
                                        <Link to="/wedo">
                                        <div className="whatwedoblock">
                                            <i class="fa fa-users"></i>
                                            <p>Custom <br />Services</p>
                                        </div>
                                        </Link>    
                                        </FadeInSection>                                    
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 whatwedodiv">
                                        <FadeInSection>
                                        <Link to="/wedo">
                                        <div className="whatwedoblock">
                                            <i class="fa fa-laptop"></i>
                                            <p>Maintenance<br /> & Support</p>
                                        </div>
                                        </Link>
                                        </FadeInSection>                                        
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div className="row">
                    <div className="container awardsection">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>Awards & Achievements</h2>
                                <p>
                                    We have been showered with a number of awards – both inside and outside the state. <br />Many of our services have been recognized, including website development, ERP and smart energy solutions.                                    
                                </p>
                                
                            </div>
                        </div>
                        <FadeInSection>
                        <div className="row">
                            {/*<div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <div className="awards svillage">
                                    <h6>Smart Village Award@Smart Cities Conference & Exhibition</h6>
                                    <p>For first Nanogrid Village in all over India.
                                    <div className="award-btn">More details</div>
                                    </p>                                    
                                </div>                                
                            </div>*/}

                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <div className="awards eindia">
                                    <h6>Achievement par Excellence: e-INDIA 2013 Award</h6>
                                    <p>The eINDIA Awards are given to innovative endeavors made in integrating technology in societal concerns.
                                    {/*<div className="award-btn">More details</div>*/}
                                    </p>
                                </div> 
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <div className="awards eodisha">
                                    <h6>Achievement par Excellence: e-ODISHA 2013 Award</h6>
                                    <p>ICT enabled Higher Education Institute award at the first Mega IT event, "eOdisha Summit", hosted by the Dept. of IT, Govt. of Odisha & Elets Technomedia.
                                    {/*<div className="award-btn">More details</div>*/}
                                    </p>
                                </div> 
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>                            
                        </div>
                        </FadeInSection>
                    </div>                   
                </div>


                {/*<div className="row">
                    <div className="container awardsection">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>Recognised by</h2>
                                <p>
                                We are Recognised BY Start-up India as well as Start-up Odisha. <br />Our aim is to achieve strategic partnerships and give you access to technology specialists from our side.                                 
                                </p>
                                
                            </div>
                        </div>
                        <FadeInSection>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <img src={StartupIndia}  alt="CollabDots" className="img-fluid" />
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                    <img src={StartupOdisha}  alt="CollabDots" className="img-fluid" />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>                            
                        </div>
                        </FadeInSection>
                    </div>                   
                </div>*/}


                <div className="row">
                    <div className="container-fluid testimonial">
                        <div className="row sectionheading">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <h1>References</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 slider">                                
                                {/*Slider */}
                                <div id="demo" className="carousel slide" data-ride="carousel">
                                <ul className="carousel-indicators">
                                <li data-target="#demo" data-slide-to="0" className="active"></li>
                                <li data-target="#demo" data-slide-to="1"></li>
                                
                                </ul>
                                
                                <div className="carousel-inner">
                                <div className="carousel-item active">
                                        <div className="carousel-caption">
                                            <div className="companyprofile">
                                                <div className="companylogo">
                                                    <img src={Sankalp} className="img-fluid" alt="CollabDot Sankalp Semiconductor" />
                                                </div>
                                                <div className="companyname">
                                                    <h5>Manager, SANKALP Semi..</h5>
                                                    <h6>Venkatesh M</h6>                                                                                                        
                                                </div>
                                            </div>
                                            <div className="arrow"><i className="fa fa-caret-down"></i></div>
                                            <h3>SANKALP Semiconductor</h3>
                                            <p>
                                                We are highly delighted with the quality of work and ownership displayed by the members of NTCS involved with us. 
                                                <br />We see true spirit of partnership from your team. 
                                                The customizations supported by your team with the quickest turn around team is remarkable. 
                                            </p>
                                        </div>
                                </div>
                                <div className="carousel-item">
                                        <div className="carousel-caption">
                                            <div className="companyprofile">
                                                <div className="companylogo">
                                                    <img src={NIST} className="img-fluid" alt="Collabdots" />
                                                </div>
                                                <div className="companyname">
                                                    <h5>Director, NIST</h5>
                                                    <h6>Prof. Sangram Mudali</h6>                                                                                                        
                                                </div>
                                            </div>
                                            <div className="arrow"><i className="fa fa-caret-down"></i></div>
                                            <h3>NIST, Berhampur</h3>
                                            <p>I have been involved in big projects and the team at NTCS is the best that I have worked with. <br />They are dedicated, efficient and use best practices in their development work.</p>
                                        </div>
                                </div>
                                {/*<div className="carousel-item">
                                        <div className="carousel-caption">
                                            <div className="companyprofile">
                                                <div className="companylogo">
                                                    <img src={sunmoksha} className="img-fluid" alt="CollabDot" />
                                                </div>
                                                <div className="companyname">
                                                    <h5>Head, Business Development</h5>
                                                    <h6>Mr. Shreedhar Rao</h6>                                                                                                        
                                                </div>
                                            </div>
                                            <div className="arrow"><i className="fa fa-caret-down"></i></div>
                                            <h3>SunMoksha</h3>
                                            <p>
                                                SunMoksha is one of the early customers of Collabdots. The team at Collabdots is young and dynamic, and have helped us build solutions to meet our requirements, with the best of technology platforms. The team at Collabdots have been working with us for several years, in their previous avatars. We look forward to continuing working with them, and wish them all the best for the future.
                                            </p>
                                        </div>
                                    </div>*/}
                                </div>

                                <a className="carousel-control-prev" href="#demo" data-slide="prev">
                                <span className="carousel-control-prev-icon"></span>
                                </a>
                                <a className="carousel-control-next" href="#demo" data-slide="next">
                                <span className="carousel-control-next-icon"></span>
                                </a>
                                </div>
                                {/*Slider */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="container-fluid servicesection">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>Team</h2>
                                <p>
                                Not every organization can boast of a team who volunteer their time and 
                                services after work hours. <br />We are proud of our team who always goes 
                                the extra mile to get the job done
                                </p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                                <marquee className="team">
                                    <img src={team1}  alt="Mr. Bhawani Shankar Pattnaik" />
                                    {/*<img src={team2}  alt="Dr. Shom Prasad Das" />*/}
                                    <img src={team3}  alt="Arnab Banerjee" />
                                    <img src={team4}  alt="Firoj Kumar Subudhi" />
                                    <img src={team5}  alt="Ujjal Dey" />
                                    <img src={team6}  alt="Arunima Sambhuta Pattanayak" />
                                    <img src={team7}  alt="Abinash Sahu" />                              
                                </marquee>
                                
                                
                                {/*<div className="home-btn teambtn" onClick={() => this.clickbutton("Team")}>Explore Our Solutions</div>*/}
                                <div className="home-btn teambtn" ><Link to="/team">View More</Link></div>
                               
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="container servicesection">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>Our Esteemed Clients</h2>
                                <p>
                                We have successfully worked with some of the leading names across various sectors and we believe our values of high level of professionalism and commitment to excellence have been the key factors in our journey.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <FadeInSection>
                                <img src={Clients}  alt="CollabDots" className="img-fluid" />
                            </FadeInSection>
                            </div>
                        </div>
                    </div>
                </div>   

            </div>     
      
        );
    }
}

export default Home