import React, {Component} from 'react';
//import TRAINING from './images/training.jpg';
//import STRAINING from './images/straining.jpg';
import eSKOOL from './images/eskool1.jpg';
import COPERP from './images/copERP1.jpg';
//import Footer from './footer'

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

class ERP extends Component{
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render(){
        return(
            <div className="container-fluid servicepage">
                <div className="row serviceheader">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        
                    </div>
                </div>

                <div className="row">
                <div className="container awardsection awardsectionservice">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                            <h2>Corporate ERP</h2>
                            <p>
                            Corporate WIKI is a smart Web based ERP solution primarily designed for Micro and Small enterprises looking for a cost-effective solution which can enable them to quickly move away from current manual processes to a fully integrated business solution.
                            </p>
                            
                        </div>
                    </div>

                <div className="row">
                    <div className="container">
                        <div className="row ERPsection">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 left">
                                <div className="row educationerp-row">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp">
                                        <p>Human Resource</p>
                                        <ul>
                                            <li>HRD Module</li>
                                            <li>Recruitment module</li>
                                            <li>Attendance & Leave Module</li>
                                        </ul>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon">
                                            <i class="fa fa-address-card"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row educationerp-row1">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp">
                                        <p>Business </p>
                                        <ul>
                                            <li>Sales & Marketing module</li>
                                            <li>ERP distribution module</li>
                                            <li>ERP business intelligence</li>
                                        </ul>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon">
                                            <i class="fa fa-briefcase"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row educationerp-row1">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp">
                                        <p>Finance Modules</p>
                                        <ul>
                                            <li>ERP payroll module</li>
                                            <li>Reimbursement Module</li>
                                            <li>Vender Payment Module</li>
                                        </ul>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon">
                                            <i class="fa fa-money"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row educationerp-row">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp">
                                        <p>Supply Chain Management</p>
                                        <ul>
                                            <li>Purchasing module</li>
                                            <li>Logistics module</li>
                                            <li>Warehousing module</li>
                                        </ul>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon">
                                            <i class="fa fa fa-truck"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 ERP">
                                <div className="blankdiv"></div>
                                <FadeInSection>
                                    <img src={COPERP} className="img-fluid" alt="CollabDots" /> 
                                </FadeInSection>                                
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 right">
                                <div className="row educationerp-row-R">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon-R">
                                            <i class="fa fa-industry"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp-R">
                                        <p>Manufacturing ERP</p>
                                        <ul>
                                            <li>Inventory management module</li>
                                            <li>Quality control module</li>
                                            <li>Materials sourcing module</li>
                                        </ul>
                                    </div>                                    
                                </div>
                                <div className="row educationerp-row1-R">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon-R">
                                            <i class="fa fa-tasks"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp-R">
                                        <p>Project Management</p>
                                        <ul>
                                            <li>Project Portfolio Management</li>
                                            <li>Performance Evolution Module</li>
                                            <li>Timesheet Module</li>
                                        </ul>
                                    </div>                                    
                                </div>
                                <div className="row educationerp-row1-R">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon-R">
                                            <i class="fa fa-cogs"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp-R">
                                        <p>Skill Enhancement</p>
                                        <ul>
                                            <li>Training Module</li>
                                            <li>Unified Lesson Learnt</li>
                                            <li>Tech Diary Module</li>
                                        </ul>
                                    </div>                                    
                                </div>
                                <div className="row educationerp-row-R">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon-R">
                                            <i class="fa fa fa-subway"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp-R">
                                        <p>Others</p>
                                        <ul>
                                            <li>Service Desk Module</li>
                                            <li>Traveling Module</li>
                                            <li>Document Module</li>
                                        </ul>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        
                </div>                   
            </div>


                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>Educational ERP</h2>
                                <p>
                                    A comprehensive school management software with <br />user friendly dashboards, easy navigation and well-structured reports.                                    
                                </p>                                
                            </div>
                        </div>
                    </div>                    
                </div>

                <div className="row m-b">
                    <div className="container">
                        <div className="row ERPsection">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 left">
                                <div className="row educationerp-row">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp">
                                        <p>Human Resource</p>
                                        <ul>
                                            <li>Employee Information</li>
                                            <li>Employee Attendance & Leave</li>
                                            <li>Employee Recruitment</li>
                                        </ul>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon">
                                            <i class="fa fa-address-card"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row educationerp-row1">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp">
                                        <p>Academics</p>
                                        <ul>
                                            <li>Syllabus & Study Material</li>
                                            <li>Homework & assignment</li>
                                            <li>Examination & marks</li>
                                        </ul>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon">
                                            <i class="fa fa-university"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row educationerp-row1">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp">
                                        <p>School Operations</p>
                                        <ul>
                                            <li>Hostel Management</li>
                                            <li>Inventory Management</li>
                                            <li>Transport Management</li>
                                        </ul>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon">
                                            <i class="fa fa-graduation-cap"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row educationerp-row">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp">
                                        <p>Finance</p>
                                        <ul>
                                            <li>Fee management</li>
                                            <li>Payroll Management</li>
                                            <li>Purchase Management</li>
                                        </ul>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon">
                                            <i class="fa fa-money"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 ERP">
                                <div className="blankdiv"></div>
                                <FadeInSection>
                                    <img src={eSKOOL} className="img-fluid" alt="CollabDots" />
                                </FadeInSection>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 right">
                                <div className="row educationerp-row-R">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon-R">
                                            <i class="fa fa-users"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp-R">
                                        <p>Student Information System</p>
                                        <ul>
                                            <li>Admission Management</li>
                                            <li>Student Information</li>
                                            <li>Student Achievements</li>
                                        </ul>
                                    </div>                                    
                                </div>
                                <div className="row educationerp-row1-R">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon-R">
                                            <i class="fa fa-envelope"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp-R">
                                        <p>Communication</p>
                                        <ul>
                                            <li>Parent Desk (Mobile App)</li>
                                            <li>SMS & Email Integration</li>
                                            <li>WhatsApp Integration</li>
                                        </ul>
                                    </div>                                    
                                </div>
                                <div className="row educationerp-row1-R">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon-R">
                                            <i class="fa fa-search"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp-R">
                                        <p>Fleet & Student Tracking</p>
                                        <ul>
                                            <li>Student Safety Management</li>
                                            <li>Student Tracking</li>
                                            <li>GPS based Vehicle Tracking System</li>
                                        </ul>
                                    </div>                                    
                                </div>
                                <div className="row educationerp-row-R">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon-R">
                                            <i class="fa fa-book"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp-R">
                                        <p>Others</p>
                                        <ul>
                                            <li>Library Management</li>
                                            <li>Activity & Event Management</li>
                                            <li>eNotice Board</li>
                                        </ul>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        );
    }
}

export default ERP;