import React, {Component} from "react";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import 'font-awesome/css/font-awesome.min.css';
//import { faHome, faCoffee, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import footerlogo from './images/logo-white.png';
import {Link} from 'react-router-dom'


class Footer extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render(){
        return(
            <footer className="container-fluid">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row">
                            <div className="container footermap">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 footermapcontent">
                                        <h4>Start a Project with NTCS</h4>
                                        <p>
                                            We are one of the leading software development companies. 
                                            We help startups and firms to create strategic software 
                                            products that drive revenue and transform their businesses and market
                                             performance.Through custom software we empower entrepreneurs, growth companies, enterprises to achieve 
                                             greater profitability and efficiency, 
                                             valuation and ultimate success by building the right tools. NTCS also gives you access to technology 
                                             specialists from our side. You can join hands with us to use our resources and our talent managed by 
                                             our highly efficient team. We are here to get things back on track, salvage what we can, rebuild 
                                             what’s needed to give your product a new life.
                                        </p>
                                       {/* <center><div className="banner-btn footer-btn">Estimate Project</div></center> */}                                       
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 socialicon">
                                        <ul>
                                            <li> <a href="https://api.whatsapp.com/send?phone=917008587101" target="_blank"><i className="fa fa-whatsapp"></i></a></li>
                                            <li> <a href="https://www.facebook.com/collabDots-102078218427002" target="_blank"><i className="fa fa-facebook-f"></i></a></li>
                                            <li> <a href="https://twitter.com/CollabDots" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                            <li> <a href="https://www.linkedin.com/company/collabdots" target="_blank"> <i className="fa fa-linkedin"></i> </a></li>
                                            <li> <a href="https://www.instagram.com/collabdots" target="_blank"><i className="fa fa-instagram"></i></a></li>                                            
                                        </ul>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="container footerlogosection">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-12 col-lg-3 col-xl-3">
                                        <Link to="/"><img src={footerlogo} className="img-fluid" alt="CollabDots" /></Link>
                                        <p></p>
                                    </div>
                                    <div className="col-lg-1 col-xl-1">

                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                                        <ul>
                                            <b>Services</b>
                                            <li><Link to="/ERP">ERP Solutions</Link></li>
                                            <li><Link to="/supermarket">RetailEZY</Link></li>
                                            <li><Link to="/medEZY">MedEZY</Link></li>
                                            <li><Link to="/Mobileapp">App Development</Link></li>
                                            <li><Link to="/iot">IoT Solutions</Link></li>
                                            <li><Link to="/ailm">AI & Machine Learning</Link></li>
                                            <li><Link to="/Training">Skill Enhancement</Link></li>                                                    
                                        </ul>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                        <ul>
                                            <li>&nbsp;</li>
                                            <li><Link to="/Company">Company</Link></li>
                                            <li><Link to="#">Portfolio</Link></li>
                                            <li><Link to="/Team">Team</Link></li>
                                            <li><Link to="/Contact">Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 footerbottom">
                                        <p>All rights reserved by CollabDots. Copyright &copy; 2020 CollabDots</p>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                    
            </footer>
        );
    }
}

export default Footer;