import React, {Component} from 'react';
import ctraining from './images/ctraining.jpg';
import straining from './images/straining.jpg';



function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }


class Training extends Component{
    render(){
        return(
            <div className="container-fluid servicepage">
                <div className="row trainingheader">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>Skill Enhancement Bootcamp</h2>
                                <p>
                                A training program allows everyone to strengthen those skills that each employee or a student needs to improve. A development program brings all employees and students to a higher level so they all have similar skills and knowledge.
                                </p>
                                <p>
                                NTCS offers a unique program called Skill Enhancement bootcamp which is divided into two parts. The first one creates a bridge between the corporate world and campus and the second one enables the professionals to enhance their skills and take it to a new level.
                                </p>
                            </div>                            
                        </div>                       
                    </div>
                </div>
                <div className="row">
                    <div className="container paddingbottom">
                        <div className="row wedosection">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h3>Corporate Training</h3>
                                <hr />
                                <p>                        
                                Corporate training can unite a large and diverse organization of the employee experience. Surveys have shown that today’s young professionals want more than just a fat salary. Prospective employees particularly value employers who offer flexibility, professional development opportunities, and a sense of purpose. They are likely to change jobs if they are unhappy with their current role. To stem this tide, it is essential for the employers to train their employees in the latest skills to keep them upgraded and enable them with an opportunity to flex their imagination and technical skills for a new range of development works.
                                </p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={ctraining} className="img-fluid" alt="CollabDots" />
                            </FadeInSection>
                            </div>
                        </div>
                        <div className="row wedosection">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={straining} className="img-fluid" alt="CollabDots" />
                            </FadeInSection>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h3>Employment Oriented Student Training</h3>
                                <hr />
                                <p>                        
                                    We believe that each of the students is having potential to succeed in campus selection process of any company or PSU. It’s only a matter of understanding the strength and weaknesses and working on them to fill the gap from being an average academic scorer to a sure shot candidate of choice for corporates or PSUs.
                                </p>
                            </div>                            
                        </div>

                    </div>                     
                </div>


            </div>            
        );
    }
}

export default Training;