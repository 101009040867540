import React from 'react';
//import logo from './logo.svg';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import 'bootstrap/dist/js/bootstrap.js';
import Header from './header';
import Home from './home';
import Wedo from './wedo';
import ERP from './erp';
import Mobileapp from './mobileapp';
import Supermarket from './supermarket';
import medEZY from './medEZY';
import Ailm from './ailm';
import IoT from './iot';
import Training from './training';
import Company from './company';
//import Eskool from './ERP/eskool'
import Portfolio from './portfolio';
import Team from './team';
import Contact from './contact';
import {HashRouter, Route, Switch} from 'react-router-dom';
//import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Footer from './footer';

function App() {
  
  return (
    <div className="App">
      

      <HashRouter>
        <Header />
          <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/wedo" component={Wedo} />
          <Route path="/erp" component={ERP} />
          <Route path="/supermarket" component={Supermarket} />
          <Route path="/medEZY" component={medEZY} />
          <Route path="/mobileapp" component={Mobileapp} />
          <Route path="/iot" component={IoT} />
          <Route path="/ailm" component={Ailm} />
          <Route path="/training" component={Training} />
          <Route path="/company" component={Company} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/team" component={Team} />
          <Route path="/contact" component={Contact} />
          </Switch>
        <Footer />     
      </HashRouter>
      
    </div>
  );
}

export default App;
