import React, {Component} from 'react';
import billing from './images/receipt_black_24dp.svg';
import inventory from './images/inventory_black_24dp.svg';
import support from './images/medication_black_24dp.svg';
import trending from './images/trending_up_black_24dp.svg';
import mobileApp from './images/book_online_black_24dp.svg';
import gst from './images/assignment_turned_in_black_24dp.svg';
import medEZYlogo from './images/medEZY.png';



//import $ from 'jquery';

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  



class medEZY extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
      }
    render(){
        return(

            <div className="container-fluid servicepage">
                <div className="row medEzybanner">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row mb-4">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <img src={medEZYlogo} className="img-fluid brandinglogo" alt="Supermarket" />
                                <p>
                                    A Smart Store Management System for all Business Needs                                   
                                </p>                              
                            </div>
                        </div>
                    </div>                    
                </div>

                <div className="row mt-3 mb-5">
                    <div className="container">
                        <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10">
                        
                            <div className="row pb-3">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 icondiv" >
                                <FadeInSection>
                                    <div className="servicediv">                                    
                                        <img src={inventory} className="img-fluid icon-mg" alt="Supermarket" />
                                        <div id="hoverShow" className="opendiv">
                                            <div className="text-center" >
                                                <img src={inventory} alt="Supermarket" />
                                            </div>                                        
                                            <hr />
                                            <span >Inventory Management</span>                                            
                                            <ul>
                                                <li>Maintain Purchase and Stock </li>
                                                <li>Seamless Stock Entry at Stockist Point</li>
                                                <li>Set Reorder Point and Alert for Expiry Batches</li>
                                            </ul>
                                        </div>                                                                      
                                    </div>
                                    <h5>Inventory</h5>
                                </FadeInSection>                                                              
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 icondiv">
                                <FadeInSection>
                                    <div className="servicediv1">                                    
                                        <img src={billing} className="img-fluid icon-mg" alt="Supermarket" />
                                        <div id="hoverShow1" className="opendiv">
                                            <div className="text-center" >
                                                <img src={billing} alt="Supermarket" />
                                            </div>                                        
                                            <hr />
                                            <span >Billing System</span>                                            
                                            <ul>
                                                <li>Easy Computerised Billing </li>
                                                <li>Show MRP, Discounts, and Rewards Earned</li>
                                                <li>Easy Refund and Cancellation Handling</li>
                                            </ul>
                                        </div>                                                                       
                                    </div>
                                    <h5>Billing</h5>
                                </FadeInSection>                                
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 icondiv">
                                <FadeInSection>
                                    <div className="servicediv2">                                    
                                        <img src={support} className="img-fluid icon-mg" alt="Supermarket" />
                                        <div id="hoverShow2" className="opendiv">
                                            <div className="text-center" >
                                                <img src={support} alt="Supermarket" />
                                            </div>                                        
                                            <hr />
                                            <span >PPP Management</span>                                            
                                            <ul>
                                                <li>Customer Database and Alert Analytics </li>
                                                <li>Process Order through Prescription Posting</li>
                                                <li>Stay Connected with Doctors and Hassle-free Reward Management</li>
                                              </ul>
                                        </div>                                                                      
                                    </div>
                                    <h5>PPP Management</h5>
                                </FadeInSection>                                
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 icondiv" >
                                <FadeInSection>
                                    <div className="servicediv3">                                    
                                        <img src={trending} className="img-fluid icon-mg" alt="Supermarket" />
                                        <div id="hoverShow3" className="opendiv">
                                            <div className="text-center" >
                                                <img src={trending} alt="Supermarket" />
                                            </div>                                        
                                            <hr />
                                            <span >Analytics</span>                                            
                                            <ul>
                                                <li>Auto Generated Periodic Reports (Sales, Profit, Customer Transaction, Order, and Purchase)</li>
                                                <li>Help Boost Customer Footfall</li>
                                                <li>Personalised/Customised Business Approach</li>
                                            </ul>
                                        </div>                                                                       
                                    </div>
                                    <h5>Analytics</h5>
                                </FadeInSection>                               
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 icondiv">
                                <FadeInSection>
                                    <div className="servicediv4">                                    
                                        <img src={mobileApp} className="img-fluid icon-mg" alt="Supermarket" />
                                        <div id="hoverShow4" className="opendiv">
                                            <div className="text-center" >
                                                <img src={mobileApp} alt="Supermarket" />
                                            </div>                                        
                                            <hr />
                                            <span >App Based Orders</span>                                            
                                            <ul>
                                                <li>Enable App and Web Based Orders </li>
                                                <li>Help Boost Business Promotion and Increase Customer Reach</li>
                                                <li>Helps Home Delivery Service</li>
                                            </ul>
                                        </div>                                                                        
                                    </div>
                                    <h5>App Based Orders</h5>
                                </FadeInSection>                               
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 icondiv">
                                <FadeInSection>
                                    <div className="servicediv5">                                    
                                        <img src={gst} className="img-fluid icon-mg" alt="Supermarket" />
                                        <div id="hoverShow5" className="opendiv">
                                            <div className="text-center" >
                                                <img src={gst} alt="Supermarket" />
                                            </div>                                        
                                            <hr />
                                            <span >GST Filing</span>                                            
                                            <ul>
                                                <li>Easily Segregate GST and Non-GST Billing </li>
                                                <li>Hassle-free GST Calculations</li>
                                                <li>Assistance in GST Filing</li>
                                            </ul>
                                        </div>                                                                      
                                    </div>
                                    <h5>GST Filing</h5>
                                </FadeInSection>                               
                                </div>
                            </div>
                        </div>
                        <div className="col-1"></div>
                            
                        </div>
                    </div>                    
                </div>

            </div>            
        );
    }
}

export default medEZY;