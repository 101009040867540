import React, {Component} from 'react';

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }


class Contact extends Component{
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render(){
        return(
            <div className="container-fluid servicepage">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pageheader">
                                <h2>Hello. What can we help you with?</h2>
                            </div>                            
                        </div>                       
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row addressblock">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                            <FadeInSection>
                                <div className="row">                                
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 contactcetogory">
                                        <h5>General</h5>
                                        <p>Ask Anything</p>
                                        <span>info@collabdots.com</span>
                                    </div>                                
                                </div>
                            </FadeInSection>
                            <FadeInSection>
                                <div className="row contactcetogoryrow">   
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 contactcetogory catogory1">
                                        <h5>Sales</h5>
                                        <p>Business development</p>
                                        <span>sales@collabdots.com</span>
                                    </div>
                                </div>
                            </FadeInSection>
                            <FadeInSection>
                                <div className="row contactcetogoryrow">                                
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 contactcetogory catogory2">
                                        <h5>Career</h5>
                                        <p>Recruiting</p>
                                        <span>career@collabdots.com</span>
                                    </div>
                                </div>
                            </FadeInSection>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 addressimg">
                                <div className="address">
                                    <h5>Our Office</h5>
                                    <h6>NTCS</h6>
                                    <p>
                                        Apex Landmark, Pahala Bhubaneswar<br />
                                        Odisha-754001<br />
                                        info@collabdots.com<br />
                                        9439960460 | 9437166101 | 9437044480
                                    </p>
                                </div>                                
                            </div>
                        </div>
                    </div>                     
                </div>
            </div>           
        );
    }
}

export default Contact;
