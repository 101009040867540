import React, {Component} from 'react';
import iot1 from './images/iot1.jpg';
import iot2 from './images/iot2.jpg';
import iot3 from './images/iot3.jpg';




function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }


class IoT extends Component{
    render(){
        return(
            <div className="container-fluid servicepage">
                <div className="row IoTheader">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>IoT Solutions</h2>
                            </div>                            
                        </div>                       
                    </div>
                </div>
                <div className="row">
                    <div className="container paddingbottom">
                        <div className="row wedosection">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h3>Energy Consumption Monitoring</h3>
                                <hr />
                                <p>                        
                                Energy consumed by machines can be monitored and managed regularly by our smart energy monitoring solutions. Embedded sensors, meters, and other IoT devices can monitor the energy utilized by each equipment. The energy utilization parameters can also be collected to determine the overall consumption of an entire area. Hence, strategic actions and decisions can be taken to subdue the consumption of electricity and boost the overall efficiency. Our Energy Monitoring System provides an easy way to automatically retrieve and analyse power quality events. Our energy monitoring system extracts the real-time energy consumption data from your plant and provides you with useful inputs on your mobile/PC. The system covers information regarding power generation and its utilization which makes it a one-stop solution. We pick the right wireless and battery-powered sensor, meter, and gateway configuration for you to transform data from endpoints into valuable information and make it available on our IoT energy monitoring dashboard which has been developed according to your requirements. 
                                </p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={iot1} className="img-fluid" alt="CollabDots" />
                            </FadeInSection>
                            </div>
                        </div>
                        <div className="row wedosection">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={iot2} className="img-fluid" alt="CollabDots" />
                            </FadeInSection>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h3>Fertigation</h3>
                                <hr />
                                <p>                                
                                The lack of water is a serious issue in India and in near future. Water services will be worst hit and ground water level in the cities will be drying up and the worst part of the crisis is Municipal water supply will be unable to deliver basic needs. Lack of proper monitoring leads to a variety of issues such as wastage of water and the main aim should be reduction in water bills by identifying and eliminating wastage. We have a HW+SW system to monitor, control and manage water. It is a convergence of IoT, IT, and clean technologies. It monitors water consumption, bore well discharge, water treatment plant output and quality. It will also monitor and control water consumption by each consumer at Household, apartments, industry, institutions, hotels, hostels, hospitals, government & public organizations, etc. It will monitor quality of water in the tanks, pumps, pipes, or treatment plants and it will end alerts as email/SMS to responsible persons for abnormal usage or malfunction. It will have a User interface over browser and mobile app. 
                                </p>
                            </div>                            
                        </div>
                        <div className="row wedosection">                            
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h3>Water Resources</h3>
                                <hr />
                                <p>                             
                                Our Smart Irrigation System is an IoT based device which is capable of automating the irrigation process by analysing the moisture of soil and the climate condition. Access to quality and affordable water resources is important in a country like India for agricultural purpose. We have addressed the issues of water availability, usage, monitoring, and conservation through a technology-cum-social innovation. We have an IoT/Cloud-based solution to remotely monitor, control, manage, and maintain water and nutrient delivery mechanisms. It delivers the right quantity of water/nutrients to the right location at the right time. It automatically monitors, and controls the supply and distribution of water and nutrients remotely.
                                </p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <FadeInSection>
                                <img src={iot3} className="img-fluid" alt="CollabDots" />
                            </FadeInSection>
                            </div>                            
                        </div>

                    </div>                     
                </div>


            </div>            
        );
    }
}

export default IoT;