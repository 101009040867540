import React, {Component} from 'react';
import Appdev from './images/mobileapp.png';

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

class Mobileapp extends Component{
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render(){
        return(
            <div className="container-fluid servicepage">
                <div className="row appdevbanner">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        
                    </div>
                </div>
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sectionheading">
                                <h2>APP Development</h2>
                                <p>
                                While mobile apps may have been traditionally associated with general productivity and information retrieval,<br /> business apps are now an essential part for many everyday operations.                                    
                                </p>                                
                            </div>
                        </div>
                    </div>                    
                </div>

                <div className="row">
                    <div className="container">
                        <div className="row mobileAppsection">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mobileAppleft">
                                <div className="row mobileApprow">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp">
                                        <p>Enterprise App Development</p>
                                        <ul>
                                            <li>Secure & Reliable</li>
                                            <li>Targeted Push Notification</li>
                                            <li>Access Control</li>
                                        </ul>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon">
                                            <i class="fa fa-building"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mobileApprow">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp">
                                        <p>Education ERP App</p>
                                        <ul>
                                            <li>Role Based Login</li>
                                            <li>Messaging Platform for Notifications and Alerts</li>
                                            <li>Completely Customizable</li>
                                        </ul>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon">
                                            <i class="fa fa-graduation-cap"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mobileApprow">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp">
                                        <p>Mobile App for IOT Solutions</p>
                                        <ul>
                                            <li>Easy customization</li>
                                            <li>Better integration</li>
                                            <li>Heightened Convenience</li>
                                        </ul>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon">
                                            <i class="fa fa-mobile"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mobileApprow">
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp">
                                        <p>Logistic And Transportation  App</p>
                                        <ul>
                                            <li>Tracking and Monitoring</li>
                                            <li>Fleet Management</li>
                                            <li>On-demand Logistics</li>
                                        </ul>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon">
                                            <i class="fa fa-truck"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mobileApp">
                                <FadeInSection>
                                    <img src={Appdev} className="img-fluid" alt="CollabDots" />
                                </FadeInSection>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mobileAppright">
                                <div className="row mobileApprow-R">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon-R">
                                            <i class="fa fa-puzzle-piece"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp-R">
                                        <p>Game Designing</p>
                                        <ul>
                                            <li>Stunning graphics</li>
                                            <li>24  × 7 Customer support</li>
                                            <li>Amazing Sound effects</li>
                                        </ul>
                                    </div>                                    
                                </div>
                                <div className="row mobileApprow-R">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon-R">
                                            <i class="fa fa-users"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp-R">
                                        <p>Crowd Sourcing  Apps</p>
                                        <ul>
                                            <li>Simple application and quick signup</li>
                                            <li>Powerful account management</li>
                                            <li>Secure Integration with payment methods</li>
                                        </ul>
                                    </div>                                    
                                </div>
                                <div className="row mobileApprow-R">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon-R">
                                            <i class="fa fa-wrench"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp-R">
                                        <p>Utility apps</p>
                                        <ul>
                                            <li>Attractive User Interface</li>
                                            <li>Simple and Efficient</li>
                                            <li>Light weight</li>
                                        </ul>
                                    </div>                                    
                                </div>
                                <div className="row mobileApprow-R">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 iconsection">
                                        <div className="educationerp-icon-R">
                                            <i class="fa fa-film"></i>
                                        </div>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 educationerp-R">
                                        <p>Entertainment apps</p>
                                        <ul>
                                            <li>Uses Artificial Intelligence and Machine Learning</li>
                                            <li>Provide best first-time experience</li>
                                            <li>Good social features</li>
                                        </ul>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        );
    }
}

export default Mobileapp;